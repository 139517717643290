import {ShopifyInstallmentsModal} from '../modal-contents';
import {ShopifyInstallmentsSamplePlansModal} from '../sample-plans-modal-contents';
import {ShopifyInstallmentsPrequalModal} from '../prequal-modal-contents';

/**
 * @param {ShopifyInstallmentsModal|ShopifyInstallmentsSamplePlansModal} contents custom component with the contents
 *     of the modal.
 */
export const openPaymentTermsModal = (
  contents:
    | ShopifyInstallmentsModal
    | ShopifyInstallmentsSamplePlansModal
    | ShopifyInstallmentsPrequalModal,
) => {
  const modalZIndex = 999999;
  const cover = document.createElement('div');
  const modal = document.createElement('div');
  cover.setAttribute('id', 'shopify-payment-terms-cover');

  Object.assign(cover.style, {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: modalZIndex,
    background: 'rgba(0,0,0,.3)',
  });

  Object.assign(modal.style, {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: modalZIndex,
    background: 'white',
    height: 'max-content',
    width: '432px',
    maxWidth: '95%',
    maxHeight: '95%',
    margin: 'auto',
    borderRadius: '7px',
    padding: '32px',
    boxSizing: 'border-box',
    overflow: 'auto',
  });
  // Mobile styles
  if (window.innerWidth < 430) {
    Object.assign(modal.style, {
      borderRadius: '0px',
      height: 'auto',
      width: 'auto',
      maxHeight: '100%',
      maxWidth: '100%',
    });
  }

  modal.appendChild(contents);

  if ('animate' in modal) {
    const coverAnimation = [{opacity: 0}, {opacity: 1}];

    const modalAnimation = [
      {transform: 'translateY(-15%)', opacity: 0},
      {transform: 'translateY(0)', opacity: 1},
    ];

    modal.animate(modalAnimation, {
      fill: 'forwards',
      duration: 150,
    });

    cover.animate(coverAnimation, {
      fill: 'forwards',
      duration: 150,
    });
  }

  cover.appendChild(modal);
  document.body.appendChild(cover);

  // Cache potential existing styles before we modify it in order to
  // provide a good experience for the modal.
  document.body.dataset.paymentTermsInitialStyles = JSON.stringify({
    overflow: document.body.style.overflow,
  });

  // Lock scrolling on the main page
  document.body.style.overflow = 'hidden';
};

/**
 * Closes the modal.
 */
export const closePaymentTermsModal = () => {
  const modal = document.querySelector('#shopify-payment-terms-cover');
  if (modal) {
    document.body.removeChild(modal);

    // Restore styles to their original values before the modal got loaded.
    if (document.body.dataset.paymentTermsInitialStyles) {
      const initialStyles = JSON.parse(
        document.body.dataset.paymentTermsInitialStyles,
      );

      document.body.style.overflow = initialStyles.overflow;
      document.body.style.position = initialStyles.position;
      delete document.body.dataset.initialStyles;
    }
  }
};
